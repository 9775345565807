var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"data-tables",class:{ 'data-tables__row-click': _vm.PCompraVisualizar },attrs:{"headers":_vm.headers,"items":_vm.compras,"items-per-page":15},on:{"click:row":_vm.openCompra},scopedSlots:_vm._u([{key:"item.data_compra",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.data_compra,"dd/MM/yyyy"))+" ")]}},{key:"item.sub_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.sub_total))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.sub_total))+" ")])]}},{key:"item.cambio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_base_sigla)+" "),(item.moeda_base_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.cambio))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.cambio))+" ")])]}},{key:"item.desconto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.desconto))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.desconto))+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.total))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === -1)?_c('div',{staticClass:"d-flex align-center justify-start"},[_c('v-sheet',{staticClass:"mr-2",staticStyle:{"border-radius":"3px"},attrs:{"width":"10px","height":"10px","color":"red lighten-1"}}),_vm._v(" "+_vm._s(_vm.$tc("global.cancelada"))+" ")],1):_vm._e(),(item.status === 0)?_c('div',{staticClass:"d-flex align-center justify-start"},[_c('v-sheet',{staticClass:"mr-2",staticStyle:{"border-radius":"3px"},attrs:{"width":"10px","height":"10px","color":"grey lighten-1"}}),_vm._v(" "+_vm._s(_vm.$tc("global.rascunho"))+" ")],1):_vm._e(),(item.status === 1)?_c('div',{staticClass:"d-flex align-center justify-start"},[_c('v-sheet',{staticClass:"mr-2",staticStyle:{"border-radius":"3px"},attrs:{"width":"10px","height":"10px","color":"yellow lighten-1"}}),_vm._v(" "+_vm._s(_vm.$tc("global.pendentes"))+" ")],1):_vm._e(),(item.status === 2)?_c('div',{staticClass:"d-flex align-center justify-start"},[_c('v-sheet',{staticClass:"mr-2",staticStyle:{"border-radius":"3px"},attrs:{"width":"10px","height":"10px","color":"green lighten-1"}}),_vm._v(" "+_vm._s(_vm.$tc("global.finalizado"))+" ")],1):_vm._e()]}},{key:"item.tipo_compra",fn:function(ref){
var item = ref.item;
return [(item.tipo_compra === 1)?_c('span',[_vm._v(" "+_vm._s(_vm.$tc("global.localSemFatura"))+" ")]):_vm._e(),(item.tipo_compra === 2)?_c('span',[_vm._v(" "+_vm._s(_vm.$tc("global.localFatura"))+" ")]):_vm._e(),(item.tipo_compra === 3)?_c('span',[_vm._v(" "+_vm._s(_vm.$tc("global.importacao"))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status === 0)?_c('span',[(_vm.PCompraDeletar)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.descartarRascunho(item)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1):_vm._e()],1):_vm._e()]}}])}),(_vm.dialogAddCompra)?_c('DialogAddCompra',{attrs:{"item":_vm.compra,"edit":_vm.edit,"dialogAddCompra":_vm.dialogAddCompra,"tipo_compra":_vm.tipo_compra},on:{"update:dialogAddCompra":function($event){_vm.dialogAddCompra=$event},"update:dialog-add-compra":function($event){_vm.dialogAddCompra=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }