<template>
  <div>
  <v-data-table
    :headers="headers"
    :items="compras"
    :items-per-page="15"
    class="data-tables"
    :class="{ 'data-tables__row-click': PCompraVisualizar }"
    @click:row="openCompra"
  >
    <template v-slot:item.data_compra="{ item }">
      {{ item.data_compra | dateFormat("dd/MM/yyyy") }}
    </template>

    <template v-slot:item.sub_total="{ item }">
      {{ item.moeda_sigla }}
      <span v-if="item.moeda_sigla === 'G$'">
        {{ item.sub_total | guarani }}
      </span>
      <span v-else>
        {{ item.sub_total | currency }}
      </span>
    </template>

    <template v-slot:item.cambio="{ item }">
      {{ item.moeda_base_sigla }}
      <span v-if="item.moeda_base_sigla === 'G$'">
        {{ item.cambio | guarani }}
      </span>
      <span v-else>
        {{ item.cambio | currency }}
      </span>
    </template>

    <template v-slot:item.desconto="{ item }">
      {{ item.moeda_sigla }}
      <span v-if="item.moeda_sigla === 'G$'">
        {{ item.desconto | guarani }}
      </span>
      <span v-else>
        {{ item.desconto | currency }}
      </span>
    </template>

    <template v-slot:item.total="{ item }">
      {{ item.moeda_sigla }}
      <span v-if="item.moeda_sigla === 'G$'">
        {{ item.total | guarani }}
      </span>
      <span v-else>
        {{ item.total | currency }}
      </span>
    </template>

    <template v-slot:item.status="{ item }">
      <div v-if="item.status === -1" class="d-flex align-center justify-start">
        <v-sheet
          width="10px"
          height="10px"
          color="red lighten-1"
          style="border-radius: 3px"
          class="mr-2"
        >
        </v-sheet>
        {{ $tc("global.cancelada") }}
      </div>

      <div v-if="item.status === 0" class="d-flex align-center justify-start">
        <v-sheet
          width="10px"
          height="10px"
          color="grey lighten-1"
          style="border-radius: 3px"
          class="mr-2"
        >
        </v-sheet>
        {{ $tc("global.rascunho") }}
      </div>

      <div v-if="item.status === 1" class="d-flex align-center justify-start">
        <v-sheet
          width="10px"
          height="10px"
          color="yellow lighten-1"
          style="border-radius: 3px"
          class="mr-2"
        >
        </v-sheet>
        {{ $tc("global.pendentes") }}
      </div>
      <div v-if="item.status === 2" class="d-flex align-center justify-start">
        <v-sheet
          width="10px"
          height="10px"
          color="green lighten-1"
          style="border-radius: 3px"
          class="mr-2"
        >
        </v-sheet>

        {{ $tc("global.finalizado") }}
      </div>
    </template>

    <template v-slot:item.tipo_compra="{ item }">
      <span v-if="item.tipo_compra === 1">
        {{ $tc("global.localSemFatura") }}
      </span>
      <span v-if="item.tipo_compra === 2">
        {{ $tc("global.localFatura") }}
      </span>
      <span v-if="item.tipo_compra === 3">
        {{ $tc("global.importacao") }}
      </span>
    </template>
    <template v-slot:item.actions="{ item }">
      <span v-if="item.status === 0">
        <v-btn
          v-if="PCompraDeletar"
          icon
          small
          @click.stop="descartarRascunho(item)"
          ><v-icon color="red">mdi-delete</v-icon></v-btn
        >
      </span>
    </template>
  </v-data-table>
  <DialogAddCompra
    v-if="dialogAddCompra"
    :item="compra"
    :edit="edit"
    :dialogAddCompra.sync="dialogAddCompra"
    :tipo_compra="tipo_compra"
  />
  </div>
</template>

<script> 
import { mapGetters } from "vuex";

export default {
  name: "ComprasTable",

  data() {
    return {
      dialogAddCompra: false,
      edit: false,
      compra: {},
      tipo_compra: null,
      com_fatura: false,
    };
  },

  props: {
    compras: {
      type: Array,
      required: true,
    },
  },

  components: {
    DialogAddCompra: () =>
      import("@/views/dashboard/compras/components/DialogAddCompra.vue"),
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    PCompraVisualizar() {
      return this.getAccess("Compras", "visualizar");
    },
    headers() {
      return [
        // {
        //   text: this.$tc("global.fornecedor"),
        //   value: "fornecedor_nome",
        // },
        {
          text: this.$tc("global.documento"),
          value: "documento",
        },
        {
          text: this.$tc("global.tipo"),
          value: "tipo_compra",
        },
        {
          text: this.$tc("global.data") + " " + this.$tc("global.compra"),
          value: "data_compra",
        },
        {
          text: this.$tc("global.cambio"),
          value: "cambio",
        },
        {
          text: "Subtotal",
          value: "sub_total",
        },
        {
          text: this.$tc("global.desconto"),
          value: "desconto",
        },
        {
          text: this.$tc("global.total"),
          value: "total",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "",
          width: "90px",
          value: "actions",
        },
      ];
    },
  },

  methods: {
    openCompra(item) {
      if (this.PCompraVisualizar) {
        this.edit = true;
        this.compra = { ...item };
        this.dialogAddCompra = true;
        if (item.tipo_compra === 2) {
          this.com_fatura = true;
        } 
      }
    },
  }
};  
</script>